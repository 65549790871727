import React from 'react';
import { graphql } from 'gatsby';
import css from 'src/theme/css';
import { createGlobalStyle } from 'styled-components';
import useHover from 'src/hooks/useHover';

import Form from 'src/components/Form';
import WebinarRecordingModule from 'src/components/WebinarRecordingModule';
import WebinarSpeakerModule from 'src/components/WebinarSpeakerModule';
import WebinarArrow from 'src/assets/svg/webinar-play.inline.svg';

import C0webm from 'src/assets/videos/webinars/C0.webm';
import C1webm from 'src/assets/videos/webinars/C1.webm';
import C2webm from 'src/assets/videos/webinars/C2.webm';
import C3webm from 'src/assets/videos/webinars/C3.webm';

import C0mp4 from 'src/assets/videos/webinars/C0.mp4';
import C1mp4 from 'src/assets/videos/webinars/C1.mp4';
import C2mp4 from 'src/assets/videos/webinars/C2.mp4';
import C3mp4 from 'src/assets/videos/webinars/C3.mp4';

const WebinarsStyle = createGlobalStyle`
  @keyframes webinarsWipe {
    from {
      opacity: 0;
      transform: translate3d(-50px, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`;

const wrapper = css({
  width: '100%',
  maxWidth: 'pageMaxWidth',
  marginRight: 'auto',
  marginLeft: 'auto',
  paddingRight: 'pageMargin',
  paddingLeft: 'pageMargin',
});

const header = css({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: ['100vh', null, null, null, '75vh'],
  marginTop: 'calc(var(--header-height) * -1)',
  paddingTop: 'var(--header-height)',
  fontFamily: 'aktiv-grotesk, sans-serif',
  fontVariantLigatures: 'common-ligatures',
});

const headerVideo = css({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const headerContainer = css({
  position: 'relative',
  display: [null, null, 'flex'],
  justifyContent: [null, null, 'space-between'],
  alignItems: [null, null, 'center'],
});

const headerMeta = (registrationClosed) =>
  css({
    animationName: 'webinarsWipe',
    animationDuration: '2.5s',
    animationTimingFunction: 'cubic-bezier(0.19, 1, 0.22, 1)',
    animationDelay: '3s',
    animationFillMode: 'both',

    flexBasis: [null, null, registrationClosed ? '100%' : '45%'],
    maxWidth: [null, null, registrationClosed ? 'none' : '550px'],
    textAlign: ['center', null, registrationClosed ? 'center' : 'left'],
    color: 'white',
  });

const headerMetaHeading = (registrationClosed) =>
  css({
    marginBottom: ['15px', null, registrationClosed ? '30px' : '20px'],
    fontSize: ['44px', null, '70px'],
    lineHeight: '1',
    letterSpacing: '-0.05em',
  });

const headerMetaSubhead = css({
  opacity: '0.8',
  marginBottom: ['30px', null, '35px'],
  fontSize: ['28px', null, '32px'],
  lineHeight: ['1.2', null, '1'],
  letterSpacing: ['-0.02em', null, '-0.05em'],
});

const headerMetaSummary = css({
  opacity: '0.8',
  marginBottom: '25px',
  fontWeight: '500',
  fontSize: ['16px', null, '20px'],
  letterSpacing: '-0.02em',
  lineHeight: '1.5',
});

const headerMetaTags = (registrationClosed) =>
  css({
    opacity: '0.7',
    display: 'flex',
    justifyContent: ['center', null, registrationClosed ? 'center' : 'flex-start'], // prettier-ignore
    alignItems: 'center',

    '&:empty': {
      display: 'none',
    },
  });

const headerMetaTag = css({
  marginRight: '5px',
  padding: '5px 15px',
  border: '1px solid white',
  borderRadius: '6px',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '1.2',
  textAlign: 'center',

  '&:last-child': {
    marginRight: 0,
  },
});

const headerForm = css({
  animationName: 'webinarsWipe',
  animationDuration: '2.5s',
  animationTimingFunction: 'cubic-bezier(0.19, 1, 0.22, 1)',
  animationDelay: '4.5s',
  animationFillMode: 'both',

  marginTop: ['85px', null, '0px'],
  marginRight: ['auto', null, '0px'],
  marginLeft: 'auto',

  form: {
    gridTemplateColumns: '1fr',
    width: ['325px', null, null, '460px', '600px'],
    margin: '0 auto !important',
  },
});

const headerFormButton = {
  backgroundColor: 'white',
  borderRadius: '10px',
  color: 'black',
};

const headerRecording = css({
  animationName: 'webinarsWipe',
  animationDuration: '2.5s',
  animationTimingFunction: 'cubic-bezier(0.19, 1, 0.22, 1)',
  animationDelay: '4.5s',
  animationFillMode: 'both',

  flexBasis: [null, null, '48.5%'],
  maxWidth: [null, null, '590px', null, 'none'],
});

const about = css({
  paddingTop: ['70px', null, '80px'],
  paddingBottom: ['70px', null, '80px'],
  backgroundColor: ['#1d1d29', null, '#12121a'],
  fontFamily: 'aktiv-grotesk, sans-serif',
  fontVariantLigatures: 'common-ligatures',
  fontWeight: '500',
  letterSpacing: '-0.02em',
  lineHeight: '1.6',
});

const aboutContainer = css({
  display: [null, null, 'flex'],
  justifyContent: [null, null, 'space-between'],
  paddingTop: [null, null, '65px'],
  paddingRight: [null, null, '75px'],
  paddingBottom: [null, null, '55px'],
  paddingLeft: [null, null, '75px'],
  borderRadius: [null, null, '8px'],
  backgroundColor: [null, null, '#1d1d29'],
  boxShadow: [null, null, '10px 8px 84px rgba(0, 0, 0, 0.35)'],
  textAlign: ['center', null, 'left'],
});

const aboutMeta = css({
  flexBasis: [null, null, '52.5%'],
  maxWidth: [null, null, '535px'],
  marginBottom: ['45px', null, '0'],
});

const aboutMetaHeading = css({
  marginBottom: '25px',
  fontWeight: '500',
  fontSize: '24px',
  lineHeight: '1.6',
  letterSpacing: '-0.02em',
});

const aboutMetaDescription = css({
  opacity: '0.7',
  marginBottom: '30px',
  fontWeight: '500',
  fontSize: ['16px', null, '18px'],
  lineHeight: '1.6',
  letterSpacing: '-0.02em',

  'ol, ul': {
    listStylePosition: ['inside', null, 'outside'],
  },
});

const aboutMetaTags = css({
  opacity: '0.7',
  display: 'flex',
  justifyContent: ['center', null, 'flex-start'],
  alignItems: 'center',

  '&:empty': {
    display: 'none',
  },
});

const aboutMetaTag = css({
  marginRight: '5px',
  padding: '5px 20px',
  border: '2px solid white',
  borderRadius: '6px',
  fontWeight: '500',
  fontSize: '13px',
  lineHeight: '1.2',

  '&:last-child': {
    marginRight: 0,
  },
});

const aboutSpeakers = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: ['center', null, 'flex-start'],
  flexBasis: [null, null, '32.5%'],
  fontFamily: 'aktiv-grotesk, sans-serif',
  fontVariantLigatures: 'common-ligatures',
  textAlign: ['center', null, 'center'],
});

const aboutSpeakersHeading = css({
  marginBottom: ['20px', null, '35px'],
  fontWeight: '500',
  fontSize: '24px',
  lineHeight: '1.6',
  letterSpacing: '-0.02em',
});

const upcoming = css({
  paddingTop: ['70px', null, '40px'],
  paddingBottom: ['65px', null, '75px'],
  backgroundColor: '#12121a',
  fontFamily: 'aktiv-grotesk, sans-serif',
  fontVariantLigatures: 'common-ligatures',
  color: 'white',
});

const upcomingHeading = css({
  maxWidth: ['190px', null, 'none'],
  marginRight: ['auto', null, '0px'],
  marginBottom: ['40px', null, '65px'],
  marginLeft: ['auto', null, '0px'],
  fontSize: ['44px', null, '60px'],
  lineHeight: '1',
  letterSpacing: '-0.05em',
  textAlign: ['center', null, 'left'],
});

const upcomingGrid = css({
  display: [null, null, 'grid'],
  gridTemplateColumns: [null, null, '1fr 1fr'],
  gridGap: [null, null, '32px'],
  justifyContent: [null, null, 'spaceBetween'],
});

const upcomingItem = (hover) =>
  css({
    overflow: 'hidden',
    transform: hover ? 'scale(1.05)' : 'none',
    transition: 'transform 1.2s cubic-bezier(0.19, 1, 0.22, 1)',
    position: 'relative',
    marginBottom: ['20px', null, '0px'],
    borderRadius: '20px',

    '&:last-child': {
      marginBottom: '0px',
    },
  });

const upcomingItemBackground = (gradient, hover) =>
  css({
    opacity: '0.9',
    transform: hover ? 'scale(1)' : 'scale(1.1)',
    transition: 'transform 1.2s cubic-bezier(0.19, 1, 0.22, 1)',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: `${gradient}, linear-gradient(180deg, rgba(0, 0, 0, 0) 34.3%, #000 100%)`,
  });

const upcomingItemContent = css({
  position: 'relative',
  padding: ['30px 30px 35px 30px', null, '45px 40px 40px 40px'],
});

const upcomingItemArrow = (hover) =>
  css({
    display: 'block',
    marginBottom: ['75px', null, '70px'],

    '*': {
      transitionProperty: 'fill, stroke',
      transitionDuration: '.3s',
      transitionTimingFunction: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
    },

    '.circle': {
      fill: hover ? '#FFF' : 'none',
    },

    '.icon': {
      fill: hover ? '#5A52FF' : '#FFF',
    },
  });

const upcomingItemDate = css({
  marginBottom: ['25px', null, '20px'],
  fontWeight: '700',
  fontSize: '14px',
  letterSpacing: '2px',
  lineHeight: '1.4',
  textTransform: 'uppercase',
});

const upcomingItemHeading = css({
  marginBottom: '20px',
  fontWeight: '500',
  fontSize: '30px',
  lineHeight: '1.25',
});

const upcomingItemSubhead = css({
  opacity: '0.8',
  maxWidth: [null, null, null, '75%'],
  fontWeight: '500',
  fontSize: '18px',
  letterSpacing: '-0.02em',
  lineHeight: '1.5',
});

const categoryLookup = (name) =>
  [
    'On The Go Review',
    'Immersive Experiences',
    'Sports',
    'Spotlight On DTC Brands',
  ].indexOf(name);

const gradientLookup = [
  'conic-gradient(from 18.08deg at -9.58% 46.01%, #3E1048 -0.3deg, #941A5C 85.35deg, #C6545A 147.3deg, #3E1048 359.7deg, #941A5C 445.35deg)',
  'conic-gradient(from 16.24deg at -9.58% 46.01%, #250945 -0.3deg, #620098 101.57deg, #57C7FF 220.11deg, #250945 359.7deg, #620098 461.57deg)',
  'conic-gradient(from 17.71deg at -11.26% 46.28%, #090B3D -8.85deg, #262097 93.32deg, #008FD3 169.74deg, #090B3D 351.15deg, #262097 453.32deg)',
  'conic-gradient(from 19.58deg at -14.37% 50%, #3E1048 -3.75deg, #8822B1 86.25deg, #4640CF 174.38deg, #3E1048 356.25deg, #8822B1 446.25deg)',
];

const videoLookup = [
  [C0webm, C0mp4],
  [C1webm, C1mp4],
  [C2webm, C2mp4],
  [C3webm, C3mp4],
];

const UpcomingItem = ({ webinar }) => {
  const [hoverRef, hover] = useHover();

  // parse date
  const raw = new Date(webinar.date);
  const day = raw.getDate();
  const month = raw.getMonth() + 1;
  const year = raw.getFullYear();
  const formatted = `Upcoming — ${month}.${day}.${year}`;

  // lookup category index
  const categoryIndex = categoryLookup(webinar.category);

  // lookup gradient
  const gradient = gradientLookup[categoryIndex];

  return (
    <div css={upcomingItem(hover)} ref={hoverRef}>
      <div css={upcomingItemBackground(gradient, hover)}></div>

      <div css={upcomingItemContent}>
        <WebinarArrow css={upcomingItemArrow(hover)} />
        <p css={upcomingItemDate}>{formatted}</p>
        <p css={upcomingItemHeading}>{webinar.title}</p>
        <p css={upcomingItemSubhead}>{webinar.summary.summary}</p>
      </div>
    </div>
  );
};

export default ({ ...data }) => {
  const renderHeader = () => {
    // registration open
    if (data.registrationStatus) {
      return (
        <div css={headerContainer}>
          <div css={headerMeta(false)}>
            <p css={headerMetaHeading(false)}>{data.title}</p>
            <p css={headerMetaSummary}>{data.summary.summary}</p>
            <div css={headerMetaTags(false)}>{renderTags(headerMetaTag)}</div>
          </div>

          <div css={headerForm}>
            <Form {...data.signupForm} buttonCss={headerFormButton} />
          </div>
        </div>
      );
    }

    // registration closed, recording available
    if (data.recording) {
      return (
        <div css={headerContainer}>
          <div css={headerMeta(false)}>
            <p css={headerMetaHeading(false)}>{data.title}</p>
            <p css={headerMetaSummary}>{data.summary.summary}</p>
            <div css={headerMetaTags(false)}>{renderTags(headerMetaTag)}</div>
          </div>

          <div css={headerRecording}>
            <WebinarRecordingModule {...data.recording} />
          </div>
        </div>
      );
    }

    // registration closed, recording unavailable
    return (
      <div css={headerContainer}>
        <div css={headerMeta(true)}>
          <p css={headerMetaHeading(true)}>Registration Closed</p>
          <p css={headerMetaSubhead}>{data.title}</p>
          <div css={headerMetaTags(true)}>{renderTags(headerMetaTag)}</div>
        </div>
      </div>
    );
  };

  const renderVideo = () => {
    // lookup category index
    const categoryIndex = categoryLookup(data.category);

    // lookup video sources
    const sources = videoLookup[categoryIndex];

    return (
      <video autoPlay css={headerVideo} muted playsInline>
        <source src={sources[0]} type="video/webm" />
        <source src={sources[1]} type="video/mp4" />
      </video>
    );
  };

  const renderSpeakers = () =>
    data.speakers && (
      <div css={aboutSpeakers}>
        <p css={aboutSpeakersHeading}>Speakers</p>

        {data.speakers.map((speaker, index) => (
          <WebinarSpeakerModule {...speaker} key={index} />
        ))}
      </div>
    );

  const renderTags = (css) => {
    return (
      data.tags &&
      data.tags.map((tag, index) => (
        <p css={css} key={index}>
          {tag}
        </p>
      ))
    );
  };

  const renderUpcoming = () => {
    return (
      data.upcoming && (
        <div css={upcoming}>
          <div css={wrapper}>
            <p css={upcomingHeading}>Upcoming Talks</p>

            <div css={upcomingGrid}>
              {data.upcoming.map((webinar, index) => (
                <UpcomingItem key={index} webinar={webinar} />
              ))}
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    <>
      <WebinarsStyle />

      <div css={header}>
        {renderVideo()}
        <div css={wrapper}>{renderHeader()}</div>
      </div>

      <div css={about}>
        <div css={wrapper}>
          <div css={aboutContainer}>
            <div css={aboutMeta}>
              <p css={aboutMetaHeading}>About The Webinar</p>

              <div
                css={aboutMetaDescription}
                dangerouslySetInnerHTML={{
                  __html: data.fullDescription.childMarkdownRemark.html,
                }}
              />

              <div css={aboutMetaTags}>{renderTags(aboutMetaTag)}</div>
            </div>

            {renderSpeakers()}
          </div>
        </div>
      </div>

      {renderUpcoming()}
    </>
  );
};

export const query = graphql`
  fragment WebinarModuleFragment on ContentfulWebinarModule {
    registrationStatus
    category
    title
    date
    summary {
      summary
    }
    fullDescription {
      childMarkdownRemark {
        html
      }
    }
    tags
    speakers {
      ... on ContentfulWebinarSpeakerModule {
        ...WebinarSpeakerModuleFragment
      }
    }
    upcoming {
      category
      title
      date
      summary {
        summary
      }
    }
    recording {
      ... on ContentfulWebinarRecordingModule {
        ...WebinarRecordingModuleFragment
      }
    }
    signupForm {
      ... on ContentfulForm {
        ...FormFragment
      }
    }
  }
`;
