import React from 'react';
import { graphql } from 'gatsby';
import css from 'src/theme/css';
import { useStore } from 'src/components/GlobalState';
import useHover from 'src/hooks/useHover';

import Img from 'gatsby-image';
import WebinarArrow from 'src/assets/svg/webinar-play.inline.svg';

const recording = (hover) =>
  css({
    overflow: 'hidden',
    transform: hover ? 'scale(1.05)' : 'none',
    transition: 'transform 1.2s cubic-bezier(0.19, 1, 0.22, 1)',
    position: 'relative',
    marginTop: ['35px', null, '0px'],
    borderRadius: ['8px', null, '16px'],
    boxShadow: ['0px 5px 20px rgba(0, 0, 0, 0.25)', null, '0px 10px 40px rgba(0, 0, 0, .25)'], // prettier-ignore
    cursor: 'pointer',
  });

const recordingBackground = (hover) => ({
  transform: hover ? 'scale(1)' : 'scale(1.1)',
  transition: 'transform 1.2s cubic-bezier(0.19, 1, 0.22, 1)',
  height: '0',
  paddingTop: '56.25%',
});

const recordingContent = css({
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: ['25px 20px 20px 20px', null, '45px 40px 40px 40px'],
});

const recordingArrow = (hover) =>
  css({
    width: ['25px', null, '45px'],
    height: ['25px', null, '45px'],
    marginBottom: 'auto',

    '*': {
      transitionProperty: 'fill, stroke',
      transitionDuration: '.3s',
      transitionTimingFunction: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
    },

    '.circle': {
      fill: hover ? '#FFF' : 'none',
    },

    '.icon': {
      fill: hover ? '#5A52FF' : '#FFF',
    },
  });

const recordingText = css({
  fontFamily: 'aktiv-grotesk, sans-serif',
  fontVariantLigatures: 'common-ligatures',
  fontWeight: '500',
  fontSize: ['13px', null, '25px'],
  lineHeight: '1.25',
});

export default (data) => {
  const [hoverRef, hover] = useHover();

  const openModal = useStore((state) => state.openModal);

  const onClick = (event) => {
    openModal('video', data.videoUrl);
  };

  return (
    <div css={recording(hover)} onClick={onClick} ref={hoverRef}>
      <Img fluid={data.previewImage.fluid} style={recordingBackground(hover)} />

      <div css={recordingContent}>
        <WebinarArrow css={recordingArrow(hover)} />
        <p css={recordingText}>Watch The Recording</p>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment WebinarRecordingModuleFragment on ContentfulWebinarRecordingModule {
    previewImage {
      fluid(maxWidth: 1000, quality: 80) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    videoUrl
  }
`;
