import { useEffect, useRef, useState } from 'react';

export default () => {
  const ref = useRef();

  const [hovered, setHovered] = useState(false);

  const onMouseOver = () => {
    // use touch class on body to ignore fake hover on mobile
    if (document.body.classList.contains('touch')) {
      return;
    }

    setHovered(true);
  };

  const onMouseOut = () => setHovered(false);

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener('mouseover', onMouseOver);
      node.addEventListener('mouseout', onMouseOut);
      return () => {
        node.removeEventListener('mouseover', onMouseOver);
        node.removeEventListener('mouseout', onMouseOut);
      };
    }
  });

  return [ref, hovered];
};
