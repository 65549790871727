import React from 'react';
import { graphql } from 'gatsby';
import css from 'src/theme/css';

import ImageGroup from 'src/components/ImageGroup';

const speaker = css({
  display: 'flex',
  flexDirection: ['column', null, 'row'],
  alignItems: 'center',
  marginBottom: ['25px', null, '20px'],

  '&:last-child': {
    marginBottom: '0',
  },
});

const speakerImage = css({
  flexShrink: [null, null, '0'],
  width: '72px',
  height: '72px',
  marginRight: [null, null, '25px'],
  marginBottom: ['10px', null, '0px'],
  borderRadius: '50%',
});

const speakerContent = css({
  opacity: '0.8',
  display: [null, null, 'flex'],
  flexDirection: [null, null, 'column'],
  fontFamily: 'aktiv-grotesk, sans-serif',
  fontVariantLigatures: 'common-ligatures',
  fontSize: '16px',
  letterSpacing: '-0.02em',
  lineHeight: '1.6',
  textAlign: [null, null, 'left'],
});

const speakerName = css({
  fontWeight: '500',
  color: 'white',
});

const speakerTitle = css({
  fontWeight: '400',
  color: '#5953FF',
});

export default (data) => (
  <div css={speaker}>
    <ImageGroup css={speakerImage} {...data.picture} />

    <div css={speakerContent}>
      <p css={speakerName}>{data.name}</p>
      <p css={speakerTitle}>{data.title}</p>
    </div>
  </div>
);

export const query = graphql`
  fragment WebinarSpeakerModuleFragment on ContentfulWebinarSpeakerModule {
    name
    title
    picture {
      ... on ContentfulImageGroup {
        ...ImageGroupFragment
      }
    }
  }
`;
